import React, { useState } from 'react';
import Modal from 'react-modal';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import close from '../../assets/images/dialog/close.svg';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import success from '../../assets/images/dialog/success.svg';
import error from '../../assets/images/dialog/error.svg';
import loader from '../../assets/images/loader.svg';

Modal.setAppElement(`#___gatsby`);

type FormData = {
  firstName: string;
  email: string;
};

type FormStatus = 'idle' | 'pending' | 'resolved' | 'rejected';

const url = 'https://api.flowyour.money/u/create-gist-contact';

interface WaitlistModalProps {
  modalVisible: boolean;
  bankName: string;
  setModalVisible(value: boolean): void;
}

const WaitlistModal = ({
  modalVisible,
  bankName,
  setModalVisible,
}: WaitlistModalProps) => {
  const { t } = useTranslation('waitlist');
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    clearErrors,
  } = useForm<FormData>();
  const [status, setStatus] = useState<FormStatus>('idle');

  // useEffect(() => {
  //   setTimeout(() => {
  //     clearErrors('firstName');
  //     clearErrors('email');
  //   }, 3000);
  // }, [errors]);

  const onSubmit = async (data: FormData) => {
    setStatus('pending');
    clearErrors('firstName');
    clearErrors('email');
    const params = new URLSearchParams();
    params.append('firstName', data.firstName);
    params.append('email', data.email);
    params.append('bank', bankName);

    const config = {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    };
    try {
      await axios.post(url, params, config);
      reset({
        firstName: '',
        email: '',
      });
      setStatus('resolved');
    } catch (error) {
      setStatus('rejected');
    }
  };

  const onModalCloseHandler = () => {
    document.body.style.overflow = 'unset';
    setModalVisible(false);
    clearErrors('firstName');
    clearErrors('email');
    setTimeout(() => {
      setStatus('idle');
    }, 2000);
  };

  return (
    <Modal
      isOpen={modalVisible}
      className="modal waitlist-modal"
      onAfterOpen={() => (document.body.style.overflow = 'hidden')}
      onRequestClose={onModalCloseHandler}
      contentLabel="Get Started with Flow"
      closeTimeoutMS={400}
    >
      <button className="modal__close" onClick={onModalCloseHandler}>
        <img src={close} alt="x" />
      </button>
      {status === 'idle' || status === 'pending' ? (
        <>
          <h2 className="u-color-primary modal__title">
            {t('defaultState.title', { bank: bankName })}
          </h2>
          <div className="u-color-primary modal__text">
            {t('defaultState.text')}
          </div>
          <form onSubmit={handleSubmit(onSubmit)} className="waitlist-form">
            <div className="waitlist-form__group">
              <input
                {...register('firstName', {
                  required: true,
                })}
                type="text"
                className="waitlist-form__control"
                placeholder={t('defaultState.firstNamePlaceholder')}
              />
              {errors?.firstName && (
                <div className="modal__error">
                  {t('defaultState.firstNameError')}
                </div>
              )}
            </div>
            <div className="waitlist-form__group">
              <input
                {...register('email', {
                  required: true,
                  pattern: /^[^\s@]+@[^\s@]+$/,
                })}
                type="text"
                className="waitlist-form__control"
                placeholder={t('defaultState.emailPlaceholder')}
              />
              {errors?.email && (
                <div className="modal__error">
                  {t('defaultState.emailError')}
                </div>
              )}
            </div>
          </form>
          <button
            className="btn btn--primary waitlist-form__btn"
            onClick={() => handleSubmit(onSubmit)()}
            disabled={status === 'pending'}
          >
            {status === 'pending' ? (
              <img src={loader} width="20px" alt="loading..." />
            ) : (
              <>{t('defaultState.buttonTitle')}</>
            )}
          </button>
        </>
      ) : (
        <>
          <img
            src={status === 'resolved' ? success : error}
            alt="x"
            className="modal__logo"
          />
          <h2 className="u-color-primary modal__title">
            {status === 'resolved' ? (
              <>{t('successState.title', { bank: bankName })}</>
            ) : (
              <>{t('errorState.title')}</>
            )}
          </h2>
          <div className="u-color-primary modal__text">
            {status === 'resolved' ? (
              <>{t('successState.text', { bank: bankName })}</>
            ) : (
              <>{t('errorState.text')}</>
            )}
          </div>
        </>
      )}
    </Modal>
  );
};

export default WaitlistModal;
