import React from 'react';

const BankCardSkeleton = () => {
  return (
    <div className="card-skeleton">
      <div className="card-skeleton__col">
        <div className="card-skeleton__logo is-animated"></div>
        <div className="card-skeleton__info">
          <h3 className="card-skeleton__title is-animated"></h3>
          <div className="card-skeleton__description is-animated"></div>
          <div className="card-skeleton__description is-animated"></div>
          <div className="card-skeleton__description is-animated"></div>
        </div>
      </div>
      <div className="card-skeleton__col">
        <div className="card-skeleton__item is-animated"></div>
        <div className="card-skeleton__item is-animated"></div>
        <div className="card-skeleton__item is-animated"></div>
        <div className="card-skeleton__item is-animated"></div>
      </div>
      <div className="card-skeleton__col">
        <div className="card-skeleton__rating">
          <div className="card-skeleton__level is-animated"></div>
          <div className="card-skeleton__block">
            <div className="card-skeleton__subtitle is-animated"></div>
            <div className="card-skeleton__grade is-animated"></div>
          </div>
        </div>
        <div className="card-skeleton__button is-animated"></div>
      </div>
    </div>
  );
};

export default BankCardSkeleton;
