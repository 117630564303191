import React from 'react';
import cx from 'classnames';
import check from '../../assets/images/banks/check.svg';
import times from '../../assets/images/banks/times.svg';
import { useTranslation, Link as Linki18n } from 'gatsby-plugin-react-i18next';
import { Link as LinkStock } from 'gatsby';
import BankCardSkeleton from './BankCardSkeleton';
import { getFlowButtonHandler } from '../Dialog';
interface BankCardProps {
  image: any;
  title: string;
  description: string;
  features: string[];
  status: 'Active' | 'Coming Soon' | 'In the future';
  details: boolean;
  setModalVisible(value: boolean): void;
  setBankName(value: string): void;
  isLoading: boolean;
  numberOfAccounts?: number;        // Used for "X Accounts In Subscription" feature.
  moreDetails?: string;
}

const BankCard = ({
  image,
  title,
  features,
  status,
  setModalVisible,
  setBankName,
  isLoading,
  numberOfAccounts,
  moreDetails
}: BankCardProps) => {
  const { t } = useTranslation('banks');
  const { i18n } = useTranslation('header');

  // Internal links should be translated, and links without a domain, protocol, etc will return an error.
  let moreDetailsIsExternal = true;
  if (moreDetails) {
    try {
      new URL(moreDetails);
    } catch(_e) {
      moreDetailsIsExternal = false;
    }
  }

  if (isLoading) {
    return <BankCardSkeleton />;
  }

  return (
    <div className="card">
      <div className="card__col">
        <div className="card__logo">
          <img src={image} alt="logo" className="card__img" />
        </div>
        <div className="card__info">
          <div className="card__title">
            <h3>
              {title}
            </h3>
            <span
              className={cx('card__status', {
                'card__status--active': status === 'Active',
                'card__status--coming': status === 'Coming Soon',
                'card__status--planned': status === 'In the future',
              })}
            >
              {t(`statuses.${status}`)}
            </span>
          </div>
          <div className="card__description">
            {t(`banks.${title}.description`)}
          </div>
        </div>
      </div>
      <div className="card__col">
        {features.map((feature) => {
          let translation;
          let icon = check;

          if (feature.slice(0,6) == 'times:') {
            icon = times;
            feature = feature.slice(6);
          }

          switch(feature) {
            case "Connect X Payment":
            case "Connect X Payment and Savings":
            case "Connect X Payment and Unlimited Savings":
            case "X Accounts In Subscription":
              translation = t(`bankFeatures.${feature}`, { count: numberOfAccounts });
              break;
            case "Get notified":
              translation = t(`bankFeatures.${feature}`, { bank: title });
              break;
            default:
              translation = t(`bankFeatures.${feature}`);
              break;
          }
          return (
            <div className="card__item">
              <img
                src={icon}
                alt="o"
                className="card__icon"
              />
              {translation}
            </div>
          );
        })}
      </div>
      <div className="card__col">
        {/* Bank is ACTIVE */}
        {status == 'Active' && (
          <button
            className="card__button btn btn--primary btn--sm"
            onClick={() => getFlowButtonHandler()}
          >
              {t(`buttons.0`)}
          </button>
        )}
        {status == 'Active' && moreDetails && (
          moreDetailsIsExternal ? (
            <LinkStock to={moreDetails}>
              {t(`buttons.2`)}
            </LinkStock>
          ) : (
            <Linki18n to={moreDetails} language={i18n.language}>
              {t(`buttons.2`)}
            </Linki18n>
          )
        )}

        {/* Bank is NOT ACTIVE */}
        {status !== 'Active' && (
          <button
            className="card__button btn btn--secondary btn--sm"
            onClick={() => {
              setModalVisible(true);
              setBankName(title);
            }}
          >
            {t(`buttons.1`)}
          </button>
        )}
        
      </div>
    </div>
  );
};

export default BankCard;
