import React, { useState } from 'react';
import { graphql, PageProps } from 'gatsby';
import Layout from '../../components/Layout';
import Seo from '../../components/SEO/seo';
// Bank logos
import monzo from '../../assets/images/banks/logos/monzo.svg';
import rabobank from '../../assets/images/banks/logos/rabobank.svg';
import revolut from '../../assets/images/banks/logos/revolut.svg';
import bunq from '../../assets/images/banks/logos/bunq.svg';
import asn from '../../assets/images/banks/logos/asn.svg';
import ing from '../../assets/images/banks/logos/ing.svg';
import knab from '../../assets/images/banks/logos/knab.svg';
import N26 from '../../assets/images/banks/logos/N26.svg';
import regiobank from '../../assets/images/banks/logos/regiobank.svg';
import sns from '../../assets/images/banks/logos/sns.svg';
import triodos from '../../assets/images/banks/logos/triodos.svg';
import amro from '../../assets/images/banks/logos/abn-amro.svg';

import BankCard from '../../components/banks/BankCard';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import WaitlistModal from '../../components/banks/WaitlistModal';

interface BankTypes {
  id: number;
  image: any;
  title: string;
  description: string;
  features: string[];
  status: 'Active' | 'Coming Soon' | 'In the future';
  details: boolean;
  numberOfAccounts?: number;        // Used for "X Accounts In Subscription" feature.
  moreDetails?: string;
}

const banks: BankTypes[] = [
  {
    id: 1,
    image: knab,
    title: 'Knab',
    description: `Knab works ideal with Flow: you have all the options for smart budgeting, achieving your savings goals and automatic money management.`,
    features: [
      "Connect X Payment and Unlimited Savings",
      "Advanced Flows",
      "Setup Flows Automatic Manual"
    ],
    status: 'Active',
    details: true,
    numberOfAccounts: 5,
    moreDetails: '/blog/business/connect-your-knab-bank-account-in-flow'
  },
  {
    id: 2,
    image: bunq,
    title: 'bunq',
    description: `With bunq you can budget, save and automate money like a pro. With our real-time connection, your bank balance in Flow is always up-to-date.`,
    features: [
      "Connect X Payment and Savings",
      "Advanced Flows",
      "Setup Flows Automatic Manual",
      "Real Time Connection"
    ],
    status: 'Active',
    details: true,
    numberOfAccounts: 25,
    moreDetails: '/blog/business/connect-your-bunq-bank-account-in-flow'
  },
  {
    id: 3,
    image: ing,
    title: 'ING',
    description: `A well-known bank with online and offline banking services. ING works well with Flow, but offers basic functionality.`,
    features: [
      "Connect Payment",
      "Basic Flows",
      "Manually Approve",
      "times:No Savings"
    ],
    status: 'Active',
    details: false,
    moreDetails: '/blog/business/connect-your-ing-bank-account-in-flow'
  },
  {
    id: 4,
    image: rabobank,
    title: 'Rabobank',
    description:
      'Rabobank is a well-known bank, and works well with Flow, but offers basic functionality.',
    features: [
      "Connect Payment",
      "Basic Flows",
      "Manually Approve",
      "times:No Savings"
    ],
    status: 'Active',
    details: false,
    moreDetails: '/blog/business/connect-your-rabobank-bank-account-in-flow'
  },
  {
    id: 5,
    image: amro,
    title: 'ABN AMRO',
    description: `At ABN AMRO, you arrange your banking in person, online or in the app. ABN AMRO works well with Flow, but offers basic functionality.`,
    features: [
      "Connect Payment",
      "Basic Flows",
      "Manually Approve",
      "times:No Savings"
    ],
    status: 'Active',
    details: false,
    moreDetails: '/blog/business/connect-your-abn-amro-bank-account-in-flow'
  },
  {
    id: 12,
    image: N26,
    title: 'N26',
    description: `N26 is not yet connected to Flow. We work hard to make this connection available soon.`,
    features: [
      "Subscribe Waiting",
      "Get notified",
      "Connect other bank"
    ],
    status: 'Coming Soon',
    details: false,
  },
  {
    id: 6,
    image: sns,
    title: 'SNS',
    description: `SNS works well with Flow, but offers basic functionality.`,
    features: [
      "Connect Payment",
      "Basic Flows",
      "Manually Approve",
      "times:No Savings"
    ],
    status: 'Active',
    details: false,
    moreDetails: '/blog/business/connect-your-sns-bank-account-in-flow'
  },
  {
    id: 7,
    image: asn,
    title: 'ASN Bank',
    description: `Sustainability is why you choose ASN. ASN works well with Flow, but offers basic functionality.`,
    features: [
      "Connect Payment",
      "Basic Flows",
      "Manually Approve",
      "times:No Savings"
    ],
    status: 'Active',
    details: false,
    moreDetails: '/blog/business/connect-your-asn-bank-account-in-flow'
  },
  {
    id: 8,
    image: monzo,
    title: 'Monzo',
    description:
      'Monzo is not yet connected to Flow. We work hard to make this connection available soon.',
    features: [
      "Subscribe Waiting",
      "Get notified",
      "Connect other bank"
    ],
    status: 'In the future',
    details: false,
  },
  {
    id: 9,
    image: revolut,
    title: 'Revolut',
    description: `Revolut is not yet connected to Flow. We work hard to make this connection available soon.`,
    features: [
      "Subscribe Waiting",
      "Get notified",
      "Connect other bank"
    ],
    status: 'In the future',
    details: false,
  },
  {
    id: 10,
    image: regiobank,
    title: 'Regiobank',
    description: `Regiobank works well with Flow, but offers basic functionality.`,
    features: [
      "Connect Payment",
      "Basic Flows",
      "Manually Approve",
      "times:No Savings"
    ],
    status: 'Active',
    details: false,
    moreDetails: '/blog/business/connect-your-regiobank-account-in-flow'
  },
  {
    id: 11,
    image: triodos,
    title: 'Triodos',
    description: `Triodos is known for their sustainability and ethics. Triodos works well with Flow, but offers basic functionality.`,
    features: [
      "Connect Payment",
      "Basic Flows",
      "Manually Approve",
      "times:No Savings"
    ],
    status: 'Active',
    details: false,
    moreDetails: '/blog/business/connect-your-triodos-bank-account-in-flow'
  },
];

const sortedBanks = ((data: BankTypes[]) => {
  const active = data.filter((el) => el.status === 'Active');
  const coming = data.filter((el) => el.status === 'Coming Soon');
  const future = data.filter((el) => el.status === 'In the future');
  return [...active, ...coming, ...future];
})(banks);

const Banks = (page: PageProps & {
  data: {
    allStorychief: {
      edges: [
        {
          node: {
            slug: string;
            language: string;
            source: {
              data: {
                slug?: string;
              }
            }
          }
        }
      ]
    }
  }
}) => {
  const { t } = useTranslation('banks');
  const { i18n } = useTranslation('header');
  const [modalVisible, setModalVisible] = useState(false);
  const [bankName, setBankName] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  React.useEffect(() => {
    setIsLoading(true);
    setTimeout(() => setIsLoading(false), 400);
  }, [false]);

  console.log(page);
  

  const slugTranslations = page.data.allStorychief.edges.reduce((prev: any, curr) => ({
    ...prev,
    ...(curr.node.source.data.slug ? {
      [curr.node.source.data.slug]: {
        ...(prev[curr.node.source.data.slug] ?? {}),
        [curr.node.language]: [curr.node.slug]
      }
    }: {})
  }), {} as {
    [key: string]: {
      [key: string]: string;
    };
  });

  return (
    <Layout>
      <Seo page={page} title="Banks Flow" />
      <main className="banks-page">
        <section className="section-1">
          <div className="container">
            <div className="section-1__content u-section-padding">
              <div className="section-1__block u-spacing-large">
                <h2 className="section-1__title u-title-primary">
                  {t('title', 'Supported Banks')}
                  <span> + Flow</span>
                </h2>
                <div className="section-1__text u-text-secondary u-color-lavender">
                  {t(
                    'description',
                    `Our company is focused on automating cashflows, hence we call
                  ourselves Flow Your Money (or Flow).`
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="section-2">
          <div className="container">
            {sortedBanks.map(({ id, ...data }) => {
              if (data.moreDetails && data.moreDetails.startsWith('/blog/')) {
                const slug = data.moreDetails.split('/').slice(-1)[0];
                data.moreDetails = [
                  ...data.moreDetails.split('/').slice(0, -1),
                  slugTranslations[slug][i18n.language] ?? slug
                ].join('/');
              }

              return (
                <BankCard
                  key={id}
                  setBankName={setBankName}
                  setModalVisible={setModalVisible}
                  isLoading={isLoading}
                  {...data}
                />
              );
            })}
          </div>
        </div>
      </main>
      <WaitlistModal
        modalVisible={modalVisible}
        bankName={bankName}
        setModalVisible={setModalVisible}
      />
    </Layout>
  );
};

export default Banks;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allStorychief(sort: { fields: [published_at], order: DESC }) {
      edges {
        node {
          language
          slug
          source {
            data {
              slug
            }
          }
        }
      }
    }
  }
`;
